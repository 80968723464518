import React, { useRef } from 'react';
import totp from 'totp-generator';
import ExampleText from '../../../components/ExampleText';
import settings from '../../../settings';

export default function({children,secretBase32,setTotp}){
    const refs	=	{
		totp	:	useRef(null),
		wrapper	:	useRef(null),
	};

	const styles=	{
		pre	:	{
			color	:	'Gold',
			margin	:	0,
		},
	};

	const formSubmit = function(event){

		const form	=	event.target;
		const input	=	form.secret_base32;

		const string	=	input.value;

		event.preventDefault();
		event.stopPropagation();

		const passcode	=	totp(string,{
			algorithm	:	'SHA-1',
			digits		:	6,
			period		:	30,	//	Seconds

		});

		refs.totp.current.innerHTML		=	passcode;
		refs.wrapper.current.style.display	=	'block';

		return false;
	};
    return (
        <div>
            <form onSubmit={formSubmit}>
                <div className="form-group">
                    <input name="secret_base32" type="text" className="form-control" placeholder="The Shared Secret buffer encoded as base64" defaultValue={secretBase32}/>
					<ExampleText>{settings.example_values.shared_secret_as_base32}</ExampleText>
                </div>
                <button type="submit" className="btn btn-primary">Get Time-Based One-Time Password</button>
            </form>
            <div ref={refs.wrapper} Style="display:none;">
				<hr />
                <table className="table table-dark">
                    <caption>Time-Based One-Time Password</caption>
                    <tbody>
						<tr>
                        	<th>TOTP</th>
							<td><pre style={styles.pre} ref={refs.totp}></pre></td>
						</tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

};